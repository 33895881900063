// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-trial-hair-growth-trial-hair-growth-index-page-tsx": () => import("./../src/templates/trial-hair-growth/trial-hair-growth-index-page.tsx" /* webpackChunkName: "component---src-templates-trial-hair-growth-trial-hair-growth-index-page-tsx" */),
  "component---src-templates-wrinkle-wrinkle-ranking-page-tsx": () => import("./../src/templates/wrinkle/wrinkle-ranking-page.tsx" /* webpackChunkName: "component---src-templates-wrinkle-wrinkle-ranking-page-tsx" */),
  "component---src-templates-medicalhair-page-tsx": () => import("./../src/templates/medicalhair-page.tsx" /* webpackChunkName: "component---src-templates-medicalhair-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-slack-and-lift-up-slack-and-lift-up-ranking-page-tsx": () => import("./../src/templates/slack-and-lift-up/slack-and-lift-up-ranking-page.tsx" /* webpackChunkName: "component---src-templates-slack-and-lift-up-slack-and-lift-up-ranking-page-tsx" */),
  "component---src-templates-index-popup-page-tsx": () => import("./../src/templates/index-popup-page.tsx" /* webpackChunkName: "component---src-templates-index-popup-page-tsx" */),
  "component---src-templates-share-pickup-news-page-tsx": () => import("./../src/templates/share-pickup-news-page.tsx" /* webpackChunkName: "component---src-templates-share-pickup-news-page-tsx" */),
  "component---src-templates-share-places-page-tsx": () => import("./../src/templates/share-places-page.tsx" /* webpackChunkName: "component---src-templates-share-places-page-tsx" */),
  "component---src-templates-share-infomation-page-tsx": () => import("./../src/templates/share-infomation-page.tsx" /* webpackChunkName: "component---src-templates-share-infomation-page-tsx" */),
  "component---src-templates-share-parts-of-trouble-page-tsx": () => import("./../src/templates/share-parts-of-trouble-page.tsx" /* webpackChunkName: "component---src-templates-share-parts-of-trouble-page-tsx" */),
  "component---src-templates-medicalhair-price-page-tsx": () => import("./../src/templates/medicalhair-price-page.tsx" /* webpackChunkName: "component---src-templates-medicalhair-price-page-tsx" */),
  "component---src-templates-share-history-page-tsx": () => import("./../src/templates/share-history-page.tsx" /* webpackChunkName: "component---src-templates-share-history-page-tsx" */),
  "component---src-templates-machines-v-shape-page-tsx": () => import("./../src/templates/machines/v-shape-page.tsx" /* webpackChunkName: "component---src-templates-machines-v-shape-page-tsx" */),
  "component---src-templates-machines-pico-laser-page-tsx": () => import("./../src/templates/machines/pico-laser-page.tsx" /* webpackChunkName: "component---src-templates-machines-pico-laser-page-tsx" */),
  "component---src-templates-machines-q-switch-ruby-page-tsx": () => import("./../src/templates/machines/q-switch-ruby-page.tsx" /* webpackChunkName: "component---src-templates-machines-q-switch-ruby-page-tsx" */),
  "component---src-templates-machines-laser-toning-page-tsx": () => import("./../src/templates/machines/laser-toning-page.tsx" /* webpackChunkName: "component---src-templates-machines-laser-toning-page-tsx" */),
  "component---src-templates-machines-laser-facial-page-tsx": () => import("./../src/templates/machines/laser-facial-page.tsx" /* webpackChunkName: "component---src-templates-machines-laser-facial-page-tsx" */),
  "component---src-templates-machines-iontophoresis-page-tsx": () => import("./../src/templates/machines/iontophoresis-page.tsx" /* webpackChunkName: "component---src-templates-machines-iontophoresis-page-tsx" */),
  "component---src-templates-gap-pore-gap-pore-ranking-page-tsx": () => import("./../src/templates/gap-pore/gap-pore-ranking-page.tsx" /* webpackChunkName: "component---src-templates-gap-pore-gap-pore-ranking-page-tsx" */),
  "component---src-templates-machines-intra-gen-page-tsx": () => import("./../src/templates/machines/intra-gen-page.tsx" /* webpackChunkName: "component---src-templates-machines-intra-gen-page-tsx" */),
  "component---src-templates-machines-e-plus-sr-page-tsx": () => import("./../src/templates/machines/e-plus-sr-page.tsx" /* webpackChunkName: "component---src-templates-machines-e-plus-sr-page-tsx" */),
  "component---src-templates-machines-internal-medicine-page-tsx": () => import("./../src/templates/machines/internal-medicine-page.tsx" /* webpackChunkName: "component---src-templates-machines-internal-medicine-page-tsx" */),
  "component---src-templates-machines-chemical-peel-page-tsx": () => import("./../src/templates/machines/chemical-peel-page.tsx" /* webpackChunkName: "component---src-templates-machines-chemical-peel-page-tsx" */),
  "component---src-templates-machines-carbon-peeling-page-tsx": () => import("./../src/templates/machines/carbon-peeling-page.tsx" /* webpackChunkName: "component---src-templates-machines-carbon-peeling-page-tsx" */),
  "component---src-templates-machines-botox-page-tsx": () => import("./../src/templates/machines/botox-page.tsx" /* webpackChunkName: "component---src-templates-machines-botox-page-tsx" */),
  "component---src-templates-dermatology-post-tsx": () => import("./../src/templates/dermatology-post.tsx" /* webpackChunkName: "component---src-templates-dermatology-post-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-freckles-freckles-ranking-page-tsx": () => import("./../src/templates/freckles/freckles-ranking-page.tsx" /* webpackChunkName: "component---src-templates-freckles-freckles-ranking-page-tsx" */),
  "component---src-templates-dullness-dullness-ranking-page-tsx": () => import("./../src/templates/dullness/dullness-ranking-page.tsx" /* webpackChunkName: "component---src-templates-dullness-dullness-ranking-page-tsx" */),
  "component---src-templates-chloasma-chloasma-ranking-page-tsx": () => import("./../src/templates/chloasma/chloasma-ranking-page.tsx" /* webpackChunkName: "component---src-templates-chloasma-chloasma-ranking-page-tsx" */),
  "component---src-templates-beauty-medical-treatment-price-page-tsx": () => import("./../src/templates/beauty-medical-treatment-price-page.tsx" /* webpackChunkName: "component---src-templates-beauty-medical-treatment-price-page-tsx" */),
  "component---src-templates-acne-acne-ranking-page-tsx": () => import("./../src/templates/acne/acne-ranking-page.tsx" /* webpackChunkName: "component---src-templates-acne-acne-ranking-page-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-dermatology-index-js": () => import("./../src/pages/dermatology/index.js" /* webpackChunkName: "component---src-pages-dermatology-index-js" */),
  "component---src-pages-tags-index-tsx": () => import("./../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */)
}

